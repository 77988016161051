import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker';
import {useEffect, useState} from 'react';
import {Image, StyleSheet, Text, TouchableHighlight, View} from 'react-native';
import {ImageInfo} from 'expo-image-picker';
import {getCameraRollPermission} from '../../util';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {RootStackParamList} from '../navigation/RootNavigator';
import {ScreenNames} from '../../enums';
import {createUser, updateUser} from '../api/userApi';

type Props = NativeStackScreenProps<RootStackParamList, ScreenNames.SLACK_PROFILE>;

const SlackProfile = ({ navigation }: Props): JSX.Element => {
    const [image, setImage] = useState<ImageInfo|null>(null);
    const [user, setUser] = useState<object| null >(null);
    const [error, setError] = useState<string|null>(null);

    useEffect(() => {
        (async () => {
            const storageUser = await AsyncStorage.getItem('user');
            const parsedStorageUser = storageUser ? JSON.parse(storageUser) : {};
            if (parsedStorageUser.profileImage) {
                setImage({ uri: `data:image/jpeg;base64,${parsedStorageUser.profileImage}` });
            } else {
                setImage(null);
            }
            setUser(parsedStorageUser);
            await getCameraRollPermission();
        })();
    }, []);

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            base64: true,
            allowsEditing: true,
            aspect: [4, 3],
        });

        if (!result.cancelled) {
            setImage(result);
        }
    };

    const confirmImage = async () => {
        try {
            if (user?._id) {
                await updateUser(user?.userId, image?.base64 || user?.profileImage);
            } else {
                await createUser(user?.userId, user?.userName, image?.base64, 'slack');
            }
            navigation.navigate(ScreenNames.SUCCESS, {});
        } catch (error: any) {
            setError(error.response.data);
        }
    };

    return (
        <View style={styles.viewStyles}>
            <View style={styles.mainView}>

                {error && <Text>{error}</Text>}

                <View style={styles.titleView}>
                    <Text style={styles.textStyles}>Welcome to Stockmoji!</Text>
                </View>

            </View>
            <View style={styles.infoView}>
                <View style={styles.instructionsView}>
                    <Text>
                        You need to pick a profile picture so we know what your face looks like.
                        For best results, upload a clear picture of your face smiling.
                        Example pic:
                    </Text>
                    <Image source={require('../../../assets/sampleProfile.png')} style={{margin: 'auto', width: 200, height: 200}}/>
                </View>
                <View style={styles.formView}>
                    {image !== null
                        ? (
                            <>
                                <Text style={styles.subTextStyles}>You already have a profile image, you silly goose. Would you like to change it?</Text>
                                <Image source={{uri: image.uri}} style={{width: 200, height: 200, margin: 'auto'}}/>
                            </>
                        )
                        : <Image source={require('../../../assets/logo.png')} style={{margin: 'auto', width: 200, height: 200}}/>
                    }

                    {image ? <View>
                            <TouchableHighlight
                                onPress={pickImage}
                                style ={styles.touchableStyles}>
                                <Text style={styles.buttonStyles}>Pick Again</Text>
                            </TouchableHighlight>
                            <TouchableHighlight
                                onPress={confirmImage}
                                style ={styles.touchableStyles}>
                                <Text style={styles.buttonStyles}>Confirm</Text>
                            </TouchableHighlight>
                        </View>
                        : <TouchableHighlight
                            onPress={pickImage}
                            style ={styles.touchableStyles}>
                            <Text style={styles.buttonStyles}>Pick an Image</Text>
                        </TouchableHighlight>
                    }
                </View>
            </View>


        </View>
    );
};

const styles = StyleSheet.create({
    buttonStyles: {
        color: '#000',
        fontWeight: 'bold',
        marginTop: '5%',
        textAlign: 'center',
    },
    mainView: {
        backgroundColor: 'white',
        width: '80%',
        textAlign: 'center',
        padding: '30px',
        borderRadius: 25,
        flexDirection: 'column',
        fontFamily: "Verdana, sans-serif"
    },
    modalBackground: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        // backgroundColor: '#FFE121',
        alignItems: 'center'
    }
    ,

    titleView: {
        marginBottom: '40px'
    },
    infoView: {
        flexDirection: 'row',
        width: '80%',
        fontFamily: "Verdana, sans-serif",
        padding: '20px'
    },
    instructionsView: {
        width: '50%',
        backgroundColor: 'white',
        textAlign: 'center',
        padding: '30px',
        borderRadius: 25,
        margin: '5px'

    },
    formView: {
        width: '50%',
        backgroundColor: 'white',
        textAlign: 'center',
        padding: '30px',
        borderRadius: 25,
        margin: '5px'
    },
    touchableStyles: {
        backgroundColor: '#FFE121',
        height: 40,
        width:160,
        borderRadius:10,
        marginLeft :'auto',
        marginRight:'auto',
        marginTop :20,
        color: '#000'
    },
    viewStyles: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#000000'
    },
    textStyles: {
        color: '#FFE121',
        fontSize: 40,
        fontWeight: 'bold',
        textShadow: "2px 2px #000"
    },
    subTextStyles: {
        color: '#000',
        fontSize: 20,
        marginBottom: '1rem'
    },
    captionStyles: {
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    }
});

export default SlackProfile;
