import {ScreenNames, StockCategories} from '../../enums';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {TabParamList} from '../navigation/TabNavigator';
import {useEffect, useState} from 'react';
import {fetchImagesForCategory} from '../../util';
import StockList from '../components/StockList';
import { CompositeScreenProps} from '@react-navigation/native';
import {RootStackParamList} from '../navigation/RootNavigator';

export interface ScreenParams {
    category: StockCategories;
}

type StockScreens = ScreenNames.STOCK | ScreenNames.CELEBRITIES;

export type Props = CompositeScreenProps<
    NativeStackScreenProps<TabParamList, StockScreens>,
    NativeStackScreenProps<RootStackParamList>
>;

const StockPicker = ({ navigation, route }: Props): JSX.Element => {
    const { category } = route.params;
    const [stockUrls, setStockUrls] = useState<string[]>([]);

    useEffect(() => {
        fetchImagesForCategory(category).then((stockUrls) => {
            setStockUrls(stockUrls);
        });
    }, []);

    const imagePressed = (imageUri: string) => navigation.navigate(ScreenNames.LOADING_IMAGE, { url: imageUri });

    return (
        <StockList stockUrls={stockUrls} onImagePress={imagePressed} />
    );
};

export default StockPicker;
