import { API_ROUTE } from '@env';
import axios from 'axios';

const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const fetchUser = async (userId: string | null) => {
    const response = await axios.get(`${API_ROUTE}/users?userId=${userId}`, { headers });
    return response.data;
};

export const createUser = async (userId: any, userName: any, profileImage: string | undefined, source: string) => {
    return axios.post(
        `${API_ROUTE}/users`,
        JSON.stringify({ userId, userName, profileImage, source }),
        { headers }
    );
};

export const updateUser = async (userId: any, profileImage: string | undefined) => {
    console.log(userId, profileImage);
    return axios.put(
        `${API_ROUTE}/users`,
        JSON.stringify({ userId, profileImage }),
        { headers }
    );
};
