import { useEffect } from 'react';
import { View, Text } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as _ from "lodash";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/RootNavigator";
import { ScreenNames } from "../../enums";

export interface ScreenParams {
    url: string;
}

type Props = NativeStackScreenProps<RootStackParamList, ScreenNames.LOADING_IMAGE>;

export const LoadingImage = ({ navigation, route }: Props) => {
    const { navigate } = navigation;
    const { url: stockUrl } = route.params;

    const getUserImageBase64 = async (): Promise<string> => {
        try {
            return await AsyncStorage.getItem('userImageBase64') || '';
        } catch (error) {
            // Error retrieving data
            console.log(error);
            return '';
        }
    }

    const getMergedImageBase64 = async (userImage: string) => {
        const endpoint = "https://c7uwwpy5a5.execute-api.us-east-1.amazonaws.com/default/stockmoji-image-process?authCode=supercalifragilisticexpialidocious";

        //comment in for troubleshooting:
        console.log("STOCK URL: ", stockUrl);
        const truncatedUrl = _.replace(stockUrl, 'https://vb-slack-memes.s3.amazonaws.com/', '');

        const formData = {
            photo: {base64: userImage},
            stockUrl: truncatedUrl
        };

        const header = {
            'content-type': 'application/json'
        };

        var request = _.extend({
            method: 'POST',
            body: JSON.stringify(formData)
        }, header);

        fetch(endpoint, request)
            .then(response => response.json())
            .then(response => {
                const image = new Image();
                image.src = `data:image/png;base64,${response.body}`;
                image.onload = () => {
                    navigate(ScreenNames.RESULTS, {
                        image: response.body,
                        dimensions: {width: image.width, height: image.height}
                    });
                }
            })
            .catch(error => {
                console.log(error);
                alert("Upload failed!");
            });
    };

    useEffect(() => {
        (async () => {
            const userImage = await getUserImageBase64();
            await getMergedImageBase64(userImage);
        })();
    }, []);

    return (
        <View style={styles.viewStyles}>
            <Text style={styles.textStyles}>
                It's Loading
            </Text>
            <Text style={styles.captionStyles}>
                Hold your horses
            </Text>
        </View>
    );
}


const styles = {
    viewStyles: {
        flex: 1,
        alignItems: 'center' as 'center',
        justifyContent: 'center' as 'center',
        backgroundColor: '#FFE121' as '#FFE121'
    },
    textStyles: {
        color: '#000000' as '#000000',
        fontSize: 40,
        fontWeight: 'bold' as 'bold',
    },
    captionStyles: {
        color: 'white' as 'white',
        fontSize: 20,
        fontWeight: 'bold' as 'bold'
    }
}

export default LoadingImage;