import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {ScreenNames, StockCategories} from '../../enums';
import Profile, {ScreenParams as ProfileParams} from '../screens/Profile';
import StockPicker, {ScreenParams as StockPickerParams} from '../screens/StockPicker';
import { Ionicons } from '@expo/vector-icons';

export type TabParamList = {
    [ScreenNames.STOCK]: StockPickerParams;
    [ScreenNames.CELEBRITIES]: StockPickerParams;
    [ScreenNames.PROFILE]: ProfileParams | undefined;
};

const Tab = createBottomTabNavigator<TabParamList>();

const TabIconSize = 24;

const TabNavigator = (): JSX.Element => {
    return (
        <Tab.Navigator initialRouteName={ScreenNames.STOCK} screenOptions={{ headerShown: false }}>
            <Tab.Screen
                name={ScreenNames.STOCK}
                component={StockPicker}
                initialParams={{ category: StockCategories.STOCK }}
                options={{ tabBarIcon: () => <Ionicons name="people-outline" size={TabIconSize} /> }}
            />
            <Tab.Screen
                name={ScreenNames.CELEBRITIES}
                component={StockPicker}
                initialParams={{ category: StockCategories.CELEBRITIES }}
                options={{ tabBarIcon: () => <Ionicons name="star-outline" size={TabIconSize} /> }}
            />
            <Tab.Screen
                name={ScreenNames.PROFILE}
                component={Profile}
                options={{ tabBarIcon: () => <Ionicons name="person-circle-outline" size={TabIconSize} /> }}
            />
        </Tab.Navigator>
    );
};

export default TabNavigator
