import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {View, StyleSheet, Text} from 'react-native';
import {useEffect} from 'react';
import {RootStackParamList} from '../navigation/RootNavigator';
import {ScreenNames, StockCategories} from '../../enums';
import {fetchUser} from "../api/userApi";

type Props = NativeStackScreenProps<RootStackParamList, ScreenNames.SPLASH>;

const queryParams = new URLSearchParams(window.location.search);
let userId = queryParams.get("userId")
let userName = queryParams.get("userName")
const source = queryParams.get("source")

const SlackSplash = ({ navigation }: Props): JSX.Element => {

    useEffect(() => {
        (async () => {
            const user = await fetchUser(userId);
            if (user?.userId) {
                await AsyncStorage.setItem('user', JSON.stringify(user));
            } else {
                await AsyncStorage.setItem('user', JSON.stringify({userId, userName, source}));
            }
            navigation.navigate(ScreenNames.SLACK_PROFILE);
        })();
    });

    return (
        <View style={styles.viewStyles}>
            <Text style={styles.textStyles}>
                Stockmojis
            </Text>
            <Text style={styles.captionStyles}>
                by Storyblocks
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    viewStyles: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFE121'
    },
    textStyles: {
        color: '#000000',
        fontSize: 40,
        fontWeight: 'bold',
    },
    captionStyles: {
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    }
});

export default SlackSplash;
