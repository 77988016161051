export enum ScreenNames {
    ROOT = 'Root',
    TAB = 'Tab',
    SPLASH = 'Splash',
    STOCK_PICKER = 'StockPicker',
    LOADING_IMAGE = 'LoadingImage',
    PROFILE = 'Profile',
    STOCK = 'Stock',
    CELEBRITIES = 'Celebrities',
    RESULTS = 'Results',
    SLACK_PROFILE = 'SlackProfile',
    SLACK_SPLASH = 'SlackSplash',
    SUCCESS = 'Success'
}

export enum StockCategories {
    STOCK = 'stock',
    CELEBRITIES = 'celebrities',
}