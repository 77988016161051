import {
    Image,
    View,
    Text,
    Dimensions,
    TouchableHighlight,
    Share,
} from 'react-native';
import { isMobile, browserName } from "react-device-detect";
import { RootStackParamList } from '../navigation/RootNavigator';
import { ScreenNames } from '../../enums';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

interface ImageDemensions {
    width: number;
    height: number;
}

export interface ScreenParams {
    image: String,
    dimensions: ImageDemensions
}

type Props = NativeStackScreenProps<RootStackParamList, ScreenNames.RESULTS>;

export const Results = ({ navigation, route }: Props) => {
    const { navigate } = navigation;
    const { params } = route;
    const { image, dimensions } = params;

    styles.imageStyles.height = dimensions.height;
    styles.imageStyles.width = dimensions.width;

    const shareImageAsync = async () => {
        Share.share({
            message: 'LOOK HOW STUNNING I LOOK',
            url: `data:image/jpg;base64,${image}`,
            title: 'Wow, did you see that?',
        }, {
            // Android only:
            dialogTitle: 'Share BAM goodness',
            // iOS only:
            excludedActivityTypes: [
                'com.apple.UIKit.activity.PostToTwitter',
            ],
        });
    };

    const windowDimensions = Dimensions.get('window');

    let imageHeight = Math.round(windowDimensions.width * .9 * dimensions.height / dimensions.width);
    let imageWidth = windowDimensions.width * .9;

    if (imageHeight > 500) {
        imageHeight = 500;
        imageWidth = (500 * dimensions.width) / dimensions.height;
    }

    const imageUri = `data:application/octet-stream;base64,${image}`;

    return (
        <View style={styles.viewStyles}>
            <Text style={styles.textStyles}>You look STUNNING</Text>

            <Image
                style={{ height: imageHeight, width: imageWidth }}
                source={{ uri: `data:image/jpg;base64,${image}` }}
                resizeMode='cover'
            />
            <TouchableHighlight
                onPress={() => navigate(ScreenNames.SPLASH)}
                style={styles.touchableStyles}>
                <Text style={styles.buttonStyles}>I don't. Pick Again.</Text></TouchableHighlight>

            {isMobile ?
                <TouchableHighlight
                onPress={shareImageAsync}
                style={styles.touchableStyles}>
                <Text style={styles.buttonStyles}>Share</Text></TouchableHighlight> :
                <a style={styles.touchableStyles} download="FILENAME.png" href={imageUri}><Text style={styles.buttonStyles}>Download</Text></a> }

        </View>
    );
};

const styles = {
    buttonStyles: {
        color: 'white' as 'white',
        fontWeight: 'bold' as 'bold',
        marginTop: '5%' as '5%',
        textAlign: 'center' as 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageStyles: {
        width: 300,
        height: 200,
    },
    touchableStyles: {
        backgroundColor: '#FFE121',
        height: 40,
        width: 160,
        borderRadius: 10,
        marginLeft: 50,
        marginRight: 50,
        marginTop: 20,
        textDecoration: 'none',
    },
    viewStyles: {
        flex: 1 as 1,
        alignItems: 'center' as 'center',
        justifyContent: 'center' as 'center',
        backgroundColor: '#000000' as '#000000',
    },
    textStyles: {
        color: '#FFE121' as '#FFE121',
        fontSize: 40 as 40,
        fontWeight: 'bold' as 'bold',
    },
    captionStyles: {
        color: 'white' as 'white',
        fontSize: 20 as 20,
        fontWeight: 'bold' as 'bold',
    },
    fitImage: {
        borderRadius: 20,
    },
    fitImageWithSize: {
        height: 100,
        width: 30,
    },
};

export default Results;