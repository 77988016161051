import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {View, StyleSheet, Text} from 'react-native';
import {RootStackParamList} from '../navigation/RootNavigator';
import {ScreenNames} from '../../enums';

type Props = NativeStackScreenProps<RootStackParamList, ScreenNames.SPLASH>;

const Success = ({ }: Props): JSX.Element => {
    return (
        <View style={styles.viewStyles}>
            <View style={styles.infoStyles}>
                <Text style={styles.title}>
                    Congrats!
                </Text>
                <Text style={styles.subTitle}>
                    You did it you silly goose
                </Text>
                <Text style={styles.subTitle}>
                    Now get to slacking. Some example commands. Check back for more!:
                </Text>
                <View>
                    <Text style={styles.slackCommand}>
                        /stockmoji towel
                    </Text>
                    <Text style={styles.slackCommand}>
                        /stockmoji happy
                    </Text>
                </View>

            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    viewStyles: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFE121'
    },
    infoStyles: {
        backgroundColor: '#FFF',
        color: '#000',
        width: '80%',
        height: '80%',
        textAlign: 'center',
        padding: 30
    },
    title: {
        fontSize: 70,
        fontWeight: 'bold'
    },
    subTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: '30px'
    },
    slackCommand: {
        borderRadius: 10,
        fontSize: 20,
        backgroundColor: '#dbdada',
        width: 'fit-content',
        margin: 'auto',
        marginTop: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '7px',
    },
    textStyles: {
        color: '#000000',
        fontSize: 40,
        fontWeight: 'bold',
    },
    captionStyles: {
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    }
});

export default Success;
