import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';
import {extend} from 'lodash';
import {StockCategories} from './enums';

export async function getCameraRollPermission(): Promise<void> {
    if (Constants?.platform?.ios) {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
            alert('Sorry, we need camera roll permissions to make this work!');
        }
    }
}

export async function fetchImagesForCategory(category: StockCategories): Promise<string[]> {
    const endpoint = `https://c7uwwpy5a5.execute-api.us-east-1.amazonaws.com/default/stockmoji-image-process?authCode=supercalifragilisticexpialidocious&category=${category}`;
    const header = { 'content-type': 'application/json' };
    const request = extend({ method: 'GET' }, header);

    const response = await fetch(endpoint, request);
    return response.json();
}
