import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {View, StyleSheet, Text} from 'react-native';
import {useCallback} from 'react';
import {RootStackParamList} from '../navigation/RootNavigator';
import {ScreenNames, StockCategories} from '../../enums';
import {useFocusEffect} from '@react-navigation/native';

type Props = NativeStackScreenProps<RootStackParamList, ScreenNames.SPLASH>;

const Splash = ({ navigation }: Props): JSX.Element => {
    useFocusEffect(useCallback(() => {
        getUserImageBase64().then((userImage) => {
            if (!userImage) {
                navigation.navigate(ScreenNames.PROFILE);
            } else {
                // load images from URL
                navigation.navigate(ScreenNames.TAB, { screen: ScreenNames.STOCK, params: { category: StockCategories.STOCK } });
            }
        })
    }, []));

    const getUserImageBase64 = async (): Promise<any> => {
        let userImageBase64;
        try {
            userImageBase64 = await AsyncStorage.getItem('userImageBase64') || null;
        } catch (error) {
            console.log(error);
        }
        return userImageBase64;
    };

    return (
        <View style={styles.viewStyles}>
            <Text style={styles.textStyles}>
                Stockmojis
            </Text>
            <Text style={styles.captionStyles}>
                by Storyblocks
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    viewStyles: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFE121'
    },
    textStyles: {
        color: '#000000',
        fontSize: 40,
        fontWeight: 'bold',
    },
    captionStyles: {
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    }
});

export default Splash;
