import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker';
import {useEffect, useState} from 'react';
import {Image, StyleSheet, Text, TouchableHighlight, View} from 'react-native';
import {ImageInfo} from 'expo-image-picker';
import {getCameraRollPermission} from '../../util';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {RootStackParamList} from '../navigation/RootNavigator';
import {ScreenNames} from '../../enums';

export interface ScreenParams {}

type Props = NativeStackScreenProps<RootStackParamList, ScreenNames.PROFILE>;

const Profile = ({ navigation }: Props): JSX.Element => {
    const [image, setImage] = useState<ImageInfo|null>(null);

    useEffect(() => {
        (async () => {
            await getCameraRollPermission()
        })();
    });

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            base64: true,
            allowsEditing: true,
            aspect: [4, 3],
        });

        if (!result.cancelled) {
            setImage(result);
        }
    };

    const confirmImage = async () => {
        console.log(image?.base64);
        try {
            await AsyncStorage.setItem('userImageBase64', image?.base64 || '');
            navigation.navigate(ScreenNames.SPLASH);
        } catch (error) {
            // Error retrieving data
            console.log(error);
        }
    };

    return (
        <View style={styles.viewStyles}>
            <Text style={styles.textStyles}>Pick an image of your face</Text>
            {image ? <Image source={{uri: image.uri}} style={{width: 200, height: 200}}/> :
                <Image source={require('../../../assets/logo.png')} style={{width: 200, height: 200}}/>
            }
            {image ? <View>
                    <TouchableHighlight
                        onPress={pickImage}
                        style ={styles.touchableStyles}>
                        <Text style={styles.buttonStyles}>Pick Again</Text>
                    </TouchableHighlight>
                    <TouchableHighlight
                        onPress={confirmImage}
                        style ={styles.touchableStyles}>
                        <Text style={styles.buttonStyles}>Confirm</Text>
                    </TouchableHighlight>
                </View>
                : <TouchableHighlight
                    onPress={pickImage}
                    style ={styles.touchableStyles}>
                    <Text style={styles.buttonStyles}>Pick an Image</Text>
                </TouchableHighlight>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    buttonStyles: {
        color: 'white',
        fontWeight: 'bold',
        marginTop: '5%',
        textAlign: 'center'
    },
    touchableStyles: {
        backgroundColor: '#FFE121',
        height: 40,
        width:160,
        borderRadius:10,
        marginLeft :50,
        marginRight:50,
        marginTop :20,
    },
    viewStyles: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000'
    },
    textStyles: {
        color: '#FFE121',
        fontSize: 40,
        fontWeight: 'bold',
    },
    captionStyles: {
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    }
});

export default Profile;
