import { map } from 'lodash';
import {Dimensions, SafeAreaView, View} from 'react-native';
import Masonry from 'react-masonry-component';

export interface StockListProps {
    stockUrls: string[];
    onImagePress: (imageUri: string) => void;
}

const StockList = ({ stockUrls, onImagePress }: StockListProps): JSX.Element => {
    const windowDimensions = Dimensions.get('window');

    const imageWidth = windowDimensions.width > 1000 ? '23vw' : '46vw';

    const getImages = () => {
        return map(stockUrls, (stockUrl) => {
            const fullUrl = `https://vb-slack-memes.s3.amazonaws.com/${stockUrl}`;
            return <img key={stockUrl} style={{width: imageWidth, paddingTop: '10px'}} onClick={() => handleImagePress(fullUrl)}
                        src={fullUrl} />;
        });
    };
    const handleImagePress = (uri: string) => {
        console.log('image press');
        onImagePress(uri);
    };

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: '#fff', flexDirection: 'row', padding: '10px'}}>
            <View style={{ flex: 1 }}>
                <Masonry
                    options={{
                        transitionDuration: 0,
                        gutter: 10,
                    }}
                    style={{'overflow-y': 'scroll'}}
                >

                    {getImages()}
                </Masonry>
            </View>
        </SafeAreaView>
    );
};

export default StockList;
