import { NavigationContainer } from '@react-navigation/native';
import RootNavigator from './src/web/navigation/RootNavigator';

//only call once
import * as dotenv from "dotenv";
dotenv.config('/Users/mrwint/Projects/stockmoji_sb/.env');



export default function App() {
  return (
      <NavigationContainer>
          <RootNavigator />
      </NavigationContainer>
  );
}
