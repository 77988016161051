import { ScreenNames } from '../../enums';
import Splash from '../screens/Splash';
import SlackSplash from '../screens/SlackSplash';
import Success from '../screens/Success';
import { NavigationContainer } from '@react-navigation/native';
import LoadingImage, { ScreenParams as LoadingImageParams } from '../screens/LoadingImage';
import Profile, { ScreenParams as ProfileParams } from '../screens/Profile';
import SlackProfile, {ScreenParams as SlackProfileParams} from '../screens/SlackProfile';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import TabNavigator, { TabParamList } from './TabNavigator';
import { NavigatorScreenParams } from '@react-navigation/native';
import Results, { ScreenParams as ResultsParams } from '../screens/Results';

export type RootStackParamList = {
    [ScreenNames.SPLASH]: undefined;
    [ScreenNames.PROFILE]: ProfileParams | undefined;
    [ScreenNames.LOADING_IMAGE]: LoadingImageParams;
    [ScreenNames.TAB]: NavigatorScreenParams<TabParamList>;
    [ScreenNames.RESULTS]: ResultsParams;
    [ScreenNames.SLACK_PROFILE]: SlackProfileParams | undefined;
    [ScreenNames.SLACK_SPLASH]: ProfileParams | undefined;
    [ScreenNames.SUCCESS]: ProfileParams | undefined;
};


const Stack = createNativeStackNavigator<RootStackParamList>();

const linking = {
    prefixes: [],
    config: {
        screens: {
            Profile: 'profile',
            SlackProfile: 'slack-profile',
            SlackSplash: 'slack-splash',
            Success: 'success'
        },
    }
}

const RootNavigator = (): JSX.Element => {
    return (
        <NavigationContainer linking={linking} independent={true}>
                <Stack.Navigator initialRouteName={ScreenNames.SPLASH} screenOptions={{ headerShown: false }}>
                    <Stack.Screen
                        name={ScreenNames.SPLASH}
                        component={Splash}
                    />
                    <Stack.Screen
                        name={ScreenNames.PROFILE}
                        component={Profile}
                    />
                    <Stack.Screen
                        name={ScreenNames.LOADING_IMAGE}
                        component={LoadingImage}
                    />
                    <Stack.Screen
                        name={ScreenNames.TAB}
                        component={TabNavigator}
                    />
                    <Stack.Screen
                        name={ScreenNames.RESULTS}
                        component={Results}
                    />
                    <Stack.Screen
                        name={ScreenNames.SLACK_PROFILE}
                        component={SlackProfile}
                    />
                    <Stack.Screen
                        name={ScreenNames.SLACK_SPLASH}
                        component={SlackSplash}
                    />
                    <Stack.Screen
                        name={ScreenNames.SUCCESS}
                        component={Success}
                    />
                </Stack.Navigator>

        </NavigationContainer>

    );
};

export default RootNavigator;
